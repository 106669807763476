import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import store from '@/store/store';
import Coverage from '../components/coverageComponent/Coverage';
import { formatNumber, hasAddressSupplement, monthYearArray } from './ValidationService';
import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { STEP } from './UiStepService';
import { GO_TO_PACKAGE_STEPS } from './NavigationService';
import { cloneDeep } from 'lodash';
import { modalHttp250Toggle } from './AlertService';
import { setHighLights, updateExcessesFromCalculation } from './ProductService';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { PackageCalculation, CommonModel, PkPrice, PkPrices } from '../types/CommonModel';
import { HomeModel } from '../home/HomeModel';
import { exception, warning } from '@/appinsights/logging/ApplicationInsightLog';
import { Calculator, CalculatorUiComponent } from '../BuyInsuranceComponent';

export const mapCoveragesFromProductConfig = (model: CommonModel, cms: BuyInsuranceSettings) => {
	model.calculation.allCoverages = new Map<number, Map<number, Array<Coverage>>>();
	model.calculation.abCalc.packs.forEach((calculations, ownRiscId) => {
		const coveragesForPackage = new Map<number, Array<Coverage>>();
		calculations.forEach((calc, packageInx) => {
			const coverages: Array<Coverage> = [];
			calc.coverages.forEach((coverage) => {
				const coverage_ = new Coverage(
					coverage.riskId + '',
					cms.getCoverageName(coverage.name),
					cms.getCoverageName(coverage.name),
					cms.getCoverageDescription(coverage.name, coverage.description)
				);
				coverages.push(coverage_);
			});
			coveragesForPackage.set(packageInx, coverages);
		});
		model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
	});
};

export const handleSpecialCarDiscounts = (model, cms): { specialDiscount: number; specialDiscountType: string } => {
	const res = { specialDiscount: 0, specialDiscountType: undefined };
	if (model.productName !== InsurancesProductEnum.BIL_FORSIKRING) {
		return res;
	}
	let addSpecialDiscount = false;

	if (cms.vehicleDiscountEnabled) {
		addSpecialDiscount = true;
		if (model.campaign.valid) {
			if (!cms.vehicleDiscountEnabledInCampaign.includes(model.campaign.ID)) {
				addSpecialDiscount = false;
			}
		}
	}
	if (addSpecialDiscount) {
		const grp = model.carInfo.vehicleGroup + '';
		if (cms.vehicleDiscountGroups.includes(grp)) {
			res.specialDiscount = cms.vehicleDiscount;
			res.specialDiscountType = 'vehicleGroup';
		}
	}

	if (model?.carInfo?.isElectricHybrid && model.calculation.discount < 1) {
		// pluskunde og el/hybrid
		addSpecialDiscount = true;
		if (model.campaign.valid) {
			if (!cms.electricFuelTypeDiscountEnabledInCampaign.includes(model.campaign.ID)) {
				addSpecialDiscount = false;
			}
		}
		if (addSpecialDiscount) {
			const grp = model.carInfo.vehicleGroup + '';
			if (cms.electricFuelTypeVehicleGroups.includes(grp)) {
				res.specialDiscount = cms.electricFuelTypeDiscount;
				res.specialDiscountType = 'fuelType';
			}
		}
	}

	return res;
};

export const mapPriceResult = (response) => {
	const res = response.data.quotes[0];
	response.data.totalPrice = res.total_price;
	response.data.statutoryFee = res.statutory_fee;
};

export const getRisksForPackage = (packageId: number, model) => {
	const pack = model.calculation.abCalc.packages.find((pack) => packageId === pack.id);

	const selectedRisks = pack.includedCoveragesExpandedIds.filter((riskNo) => {
		if (riskNo > -1) {
			return riskNo;
		}
	});

	// find coverages in riskGroups
	const risksInGroups: Array<number> = [];
	selectedRisks.forEach((riskId) => {
		model.calculation.abCalc.coveragesDetails.forEach((coverage) => {
			if (coverage.riskId !== -1 && coverage.riskGroup === riskId && coverage.riskId !== riskId) {
				risksInGroups.push(coverage.riskId);
			}
		});
	});

	const res = selectedRisks.concat(risksInGroups).sort((a, b) => {
		return a - b;
	});

	return res.toString();
};

export const setPrices = (
	packageId,
	response,
	monthly: boolean,
	pack: PackageCalculation[],
	model: CommonModel,
	cms: BuyInsuranceSettings,
	specialProductDiscount = 0
) => {
	pack.forEach((calc: PackageCalculation) => {
		if (calc.id === packageId) {
			if (monthly) {
				response.data.totalPrice = response.data.totalPrice / 12; // always receive yearly price
				response.data.statutoryFee = response.data.statutoryFee / 12; // always receive yearly fee
			}

			handlePlusCustomerCalculation(response, cms);
			// vehicleGroup/fuelType Discount for now
			calc.specialDiscount = specialProductDiscount ? specialProductDiscount : undefined;

			if (model.calculation?.addressSupplement > 1) {
				response.data.totalPrice *= model.calculation.addressSupplement; // adresse tillæg (60%)
			}

			monthly ? setMonthlyPrices(response, calc, model) : setYearlyPrices(response, calc, model);
		}
	});
};

const handlePlusCustomerCalculation = (response, cms: BuyInsuranceSettings) => {
	if (cms.plusCustomerCalculation) {
		let totalPrice = response.data.totalPrice; // - response.data.statutoryFee;
		totalPrice *= 1.05; // PK1
		const statutoryFee = totalPrice * 0.03525;
		response.data.totalPrice = totalPrice; // + statutoryFee;
		response.data.statutoryFee = statutoryFee;
	}
};

const setMonthlyPrices = (response, calc: PackageCalculation, model: CommonModel) => {
	calc.statutoryFee = response.data.statutoryFee;
	calc.basePrice = response.data.totalPrice;

	setAllPlusCustomerPrices(model, calc, true);
};

const setYearlyPrices = (response, calc: PackageCalculation, model: CommonModel) => {
	calc.yearlyBasePrice = response.data.totalPrice;
	calc.yearlyStatutoryFee = response.data.statutoryFee;

	setAllPlusCustomerPrices(model, calc, false);
};

const plusCustomerLevels = [1, 0.95, 0.9, 0.85];

export const setAllPlusCustomerPrices = (model: CommonModel, calc: PackageCalculation, monthly: boolean) => {
	let calculatedDiscount: number;
	let price: number;
	let priceAndStatutoryFee;

	let totalPriceTmp = monthly ? calc.basePrice : calc.yearlyBasePrice;
	const statutoryFee = monthly ? calc.statutoryFee : calc.yearlyStatutoryFee;

	totalPriceTmp -= statutoryFee;

	plusCustomerLevels.forEach((plusCustomerLevelDiscount, inx) => {
		calculatedDiscount = plusCustomerLevelDiscount;

		if (model.campaign.valid) {
			// add campaign discount if any
			calculatedDiscount -= model.campaign.discount;
		}

		price = totalPriceTmp * calculatedDiscount;

		// special discount
		if (calc.specialDiscount) {
			price -= price * calc.specialDiscount;
		}

		priceAndStatutoryFee = price + statutoryFee;

		const pkPrice: PkPrice = {
			price: Math.round(priceAndStatutoryFee),
			display: `${formatNumber.format(Math.round(priceAndStatutoryFee))} kr. ${monthly ? 'månedligt' : 'årligt'}}`,
		};
		if (!calc.pkPrices) {
			calc.pkPrices = <PkPrices>{
				monthly: {},
				yearly: {},
			};
		}
		if (monthly) {
			calc.pkPrices.monthly['pk' + inx] = pkPrice;
		} else {
			calc.pkPrices.yearly['pk' + inx] = pkPrice;
		}

		if (plusCustomerLevelDiscount === model.calculation.discount) {
			calc.currentPlusCostumerLevel = 'pk' + inx;
			if (monthly) {
				calc.totalPrice = Math.round(priceAndStatutoryFee);
				calc.totalPriceDisplay = formatNumber.format(calc.totalPrice) + ' kr.';
			} else {
				calc.yearlyPriceTotal = Math.round(priceAndStatutoryFee);
				calc.yearlyPriceTotalDisplay = formatNumber.format(calc.yearlyPriceTotal) + ' kr.';
			}
		}
	});
};

/** calculate pluskunde discount
 *  EKS.
    Børnefamilieforsikring | (Indboforsikring + Ulykkesforsikring)	5 %
    Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring	10 %
    Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring + Bilforsikring	15 %
*/
export const getDiscount = (cms: any, useActiveCalculator = false): { discount: number; discountDisplay: string } => {
	const rebate = { discount: 1, discountDisplay: undefined };
	let products = [];

	// get commonModel for calculators
	const commonPersonInfo = store.getters.getCalculatorCommonModel?.personInfo;
	let personInfo = commonPersonInfo;

	if (!personInfo?.almbrandCustomer || useActiveCalculator) {
		// get from active calculator
		personInfo = store.getters.getActiveCalculator?.model?.personInfo;
	}

	if (personInfo?.existingAlmBrandProducts) {
		products = Object.assign([], personInfo.existingAlmBrandProducts);
		products = products.map((product) => product.toLocaleLowerCase());
		if (useActiveCalculator && commonPersonInfo) {
			// update commonData
			commonPersonInfo.existingAlmBrandProducts = products;
		}
	}

	const basket = store.getters.getSelectableGroupUnique('basket');

	basket.forEach((product) => {
		if (!products.includes(product)) {
			products.push(product);
		}
	});
	// find primary product, and remove if present (homeInsurance)
	let count = 0;
	cms.discountPrimaryProducts.forEach((primaryProduct) => {
		if (products.includes(primaryProduct.toLocaleLowerCase())) {
			count = 1; // only one product will count
		}
	});
	// no discount
	if (count === 0) {
		return rebate;
	}
	// find first secondary product, and remove if present (accident, car, house or vacationHouse)
	cms.discountSecondaryProducts.forEach((secondaryProduct) => {
		if (products.includes(secondaryProduct.toLocaleLowerCase())) {
			count++;
		}
	});
	// Børnefamilieforsikring || børneulykke
	if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
		if (
			products.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) ||
			products.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)
		) {
			count++;
		}
	}
	// kun en af hus- fritidshus forsikring gælder
	if (
		products.includes(InsurancesProductEnum.HUS_FORSIKRING) &&
		products.includes(InsurancesProductEnum.FRITIDSHUS_FORSIKRING)
	) {
		count--;
	}

	// no discount, only indbo
	if (count === 1) {
		return rebate;
	}
	// find correct discount -> count remaining products and set discount
	switch (count) {
		case 2:
			rebate.discount = 0.95;
			break;
		case 3:
			rebate.discount = 0.9;
			break;
		default:
			rebate.discount = 0.85;
			break;
	}

	const discount = Math.abs(rebate.discount - 1);
	rebate.discountDisplay = Number(discount).toLocaleString('da-DK', {
		style: 'percent',
		minimumFractionDigits: 0,
	});
	return rebate;
};

/** discount depending on amount of selected products */
export const getDiscountForAmountProducts = (campaign) => {
	if (campaign.productStepDiscount) {
		const basket = store.getters.getSelectableGroup('basket');
		const productAmount = basket.length;
		if (productAmount >= campaign.productStepDiscount.length) {
			return campaign.productStepDiscount[campaign.productStepDiscount.length - 1];
		}
		switch (productAmount) {
			case 0:
			case 1:
				return campaign.productStepDiscount[0];
			default:
				return campaign.productStepDiscount[productAmount - 1];
		}
	}
	return campaign.discount;
};

export const reducePack = (pack): PackageCalculation => {
	return {
		coverages: pack.coverages,
		expandedCoverages: pack.expandedCoverages,
		name: pack.name,
		id: pack.id,
	};
};

export const resetSelectedCalculation = (model: CommonModel) => {
	model.choosePackage.selectedPackage = undefined;
	model.choosePackage.subtitle = undefined;
	model.choosePackage.ownRiskId = undefined;
	model.calculation.isCalculated = false;
	model.overview.highlights = undefined;
	model.overview.details = undefined;
	model.readyForBasket = false;
	// set to monthly payment as default
	model.choosePackage.monthYear = 'M';
	store.state.calculatorContext.calculating = false;
};

export const subscribeBasketChange = (uiComp: CalculatorUiComponent): Function => {
	return store.subscribeAction((action, state) => {
		if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
			if (uiComp.model.currentCardName === STEP.PACKAGE) {
				if (uiComp.model?.calculation?.isCalculated) {
					// redo calculation if pluscustomer discount has changed after basket change
					const rebate = getDiscount(uiComp.cms);
					if (rebate.discount !== uiComp.model.calculation.discount) {
						uiComp.calculator.getCalculations();
					}
				}
				return;
			}
			if (
				GO_TO_PACKAGE_STEPS.includes(uiComp.model.currentCardName) &&
				uiComp.model.currentCardName !== STEP.ORDER
			) {
				resetSelectedCalculation(uiComp.model);
				uiComp.gotoCard(STEP.PACKAGE);
			}
		}
	});
};

export const setUpExcesses = (model, excessList?: []) => {
	if (!excessList) {
		// hardcode dummy excess
		model.calculation.abCalc.excesses = [
			{
				// "amount": 3229,
				id: -1,
				minCustomerAge: 18,
			},
		];
		model.ownRiskOptions = [];
		model.ownRiskLabels = [];
	} else {
		model.calculation.abCalc.excesses = excessList;
		model.ownRiskOptions = [];
		model.ownRiskLabels = [];

		model.calculation.abCalc.excesses.forEach((excess) => {
			if (model.personInfo.customerAge >= excess.minCustomerAge) {
				model.ownRiskOptions.push(excess.id);
				model.ownRiskLabels.push(formatNumber.format(excess.amount) + ' kr.');
			}
		});
	}
};

export const setupPackages = (model, cms: BuyInsuranceSettings, calcConfig) => {
	model.calculation.abCalc.packages.forEach((pack) => {
		pack.coverages = [];
		if (cms.extendCoverages) {
			pack.includedCoverageIds.forEach((riskId) => {
				const coverage = calcConfig.coverages.find((x) => riskId === x.riskId);
				pack.coverages.push(coverage);
			});
			pack.expandedCoverages = [];
			pack.includedCoveragesExpandedIds.forEach((riskId) => {
				const coverage = calcConfig.coverages.find((x) => riskId === x.riskId);
				pack.expandedCoverages.push(cms.getCoverageName(coverage.name));
			});
		} else {
			pack.includedCoveragesExpandedIds.forEach((riskId) => {
				const coverage = calcConfig.coverages.find((x) => riskId === x.riskId);
				pack.coverages.push(coverage);
			});
		}
		cms.mapCoverageGroups(pack.coverages);
	});

	model.calculation.abCalc.packs = new Map<number, Object>();
	model.calculation.abCalc.excesses.forEach((excess) => {
		const calcs = [];
		model.calculation.abCalc.packs.set(excess.id, calcs);
		model.calculation.abCalc.packages.forEach((pack) => {
			calcs.push(reducePack(cloneDeep(pack)));
		});
	});
	model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

	setGlobalSumsFromProductConfig(model, calcConfig);
};

const setGlobalSumsFromProductConfig = (model: CommonModel, calcConfig) => {
	if (calcConfig.globalPackageSums && calcConfig.globalPackageSums.length > 0) {
		model.calculation.abCalc.globalSums = [...calcConfig.globalPackageSums];
	}
};

export const checkFirstPackagePrices = (model, excessId: number): boolean => {
	let ok = true;
	const packages = model.calculation.abCalc.packs.get(excessId);
	packages.forEach((calc) => {
		if (!calc.totalPrice) {
			ok = false;
		}
	});
	return ok;
};

export const prepareCalculations = (
	model: CommonModel,
	cms: BuyInsuranceSettings,
	resetPrices: boolean,
	excessId?: number
) => {
	if (!model.calculation.isCalculated) {
		store.state.showSpinner = true;
		store.state.calculatorContext.calculating = true;
	}
	if (resetPrices) {
		model.calculation.abCalc.packs.get(excessId || model.calculation.abCalc.excessIdDefault).forEach((pack) => {
			pack.totalPriceDisplay = undefined;
		});
	}
};

export const finishCalculation = (model: CommonModel, success: boolean) => {
	model.calculation.isCalculated = success;
	store.state.showSpinner = false;
	store.state.calculatorContext.calculating = false;
};

export const setupCalculator = (calculator: Calculator, calculatorInfo) => {
	if (!calculator.calcConfig) {
		if (calculator.model.campaign.valid) {
			calculator.cms.calculatorConfigId = calculator.cms.campaigns.get(calculator.model.campaign.ID).blockId;
		}

		const calculatorInfo_ = calculatorInfo.find(
			(cfg) => cfg.calculatorConfigId === calculator.cms.calculatorConfigId
		);

		calculator.calcConfig = calculatorInfo_.insuranceConfiguration;
	}
	calculator.model.calculation.abCalc = {
		excessIdDefault: calculator.calcConfig.excessIdDefault,
		packageIds: calculator.calcConfig.packageIds,
		packages: cloneDeep(calculator.calcConfig.packages),
		coveragesDetails: calculator.calcConfig.coverages,
	};

	calculator.setupExcessList();
};

export const updateCalcKeys = (model: CommonModel) => {
	model.calculation.updatePrices = model.calculation.updatePrices.map((elem) => {
		return (elem += '1');
	});
};

export const setupCalculation = (calculator: Calculator) => {
	calculator.successCalc = true;

	const rebate = getDiscount(calculator.cms);
	calculator.model.calculation.discount = rebate.discount;
	calculator.model.calculation.discountDisplay = rebate.discountDisplay;
};

export const callAllCalculations = async (
	calculator: Calculator,
	excessId: number,
	addressSupplement: boolean,
	errorMsg: string,
	finishModel = true
): Promise<boolean> => {
	let monthly = true;
	try {
		setupCalculation(calculator);
		if (addressSupplement) {
			if (hasAddressSupplement(calculator.model, calculator.cms)) {
				calculator.model.calculation.addressSupplement = calculator.cms.addressSupplement;
			} else {
				calculator.model.calculation.addressSupplement = 1;
			}
		}

		// reset calculation
		calculator.setUpConfig();

		let success = true;

		success = await doCallCalculations(calculator, monthly, excessId);

		// check if has got prices
		if (!success) {
			success = checkFirstPackagePrices(calculator.model, excessId);
		}
		if (success) {
			monthly = false;
			doCallCalculations(calculator, monthly, excessId);
		}

		if (finishModel) {
			finishCalculation(calculator.model, success);
		}

		return success;
	} catch (ex) {
		exception(ex);
		if (monthly) {
			modalHttp250Toggle(calculator.cms, 400, errorMsg, calculator);
		}
		return Promise.resolve(false);
	}
};

const doCallCalculations = async (calculator: Calculator, monthly: boolean, excessId: number): Promise<boolean> => {
	const promises = [];
	calculator.model.calculation.abCalc.packageIds.forEach((packageId) => {
		promises.push(calculator.onePackageCalcEpi(monthly, excessId, packageId));
	});
	await Promise.all(promises);
	return Promise.resolve(calculator.successCalc);
};

export const onePackageCalcEpi = async (
	calculator,
	monthly: boolean,
	excessId: number,
	packageId: number,
	doUpdateCalcKeys: boolean,
	errorMsg: string
) => {
	const response = await calculator.axiosService.getRetryDefaultUrl(
		calculator.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
	);

	if (response?.status !== 200) {
		calculator.successCalc = false;
		modalHttp250Toggle(calculator.cms, response?.status, errorMsg, calculator);
		return Promise.resolve(false);
	}

	const pack = calculator.model.calculation.abCalc.packs.get(excessId);
	mapPriceResult(response);
	setPrices(packageId, response, monthly, pack, calculator.model, calculator.cms);

	if (!calculator.model.updatedExcessListFromProduct) {
		updateExcessesFromCalculation(response, calculator.calcConfig);
		calculator.setupExcessList();
		calculator.model.updatedExcessListFromProduct = true;
	}

	if (doUpdateCalcKeys) {
		updateCalcKeys(calculator.model);
	}
	return Promise.resolve(true);
};

export const EpiParamsHomeFamily = (model: HomeModel) => {
	const customerAge = parseInt(model.personInfo.customerAge.trim().replace(/\D/g, ''));
	const streetBuildingLetter = model.personInfo.houseNr.trim().replace(/\d/g, ''); // remove numbers
	const streetNumber = model.personInfo.houseNr.trim().replace(/\D/g, ''); // remove characters

	let suite: number;
	let floorSideCode: string;
	if (model.personInfo.doorNr) {
		suite =
			model.personInfo.doorNr.trim().replace(/\d/g, '') === ''
				? parseInt(model.personInfo.doorNr.trim())
				: undefined;
		floorSideCode =
			model.personInfo.doorNr.trim().replace(/\D/g, '') === '' ? model.personInfo.doorNr.trim() : undefined;
	}

	let insuredValue = model.choosePackage.insuredValueAmount;
	if (!insuredValue) {
		insuredValue = model.calculation.abCalc.insuredValueOptions[0].value;
	}
	return { customerAge, streetBuildingLetter, streetNumber, suite, floorSideCode, insuredValue };
};

export const setAccidentPrices = (
	calc: PackageCalculation,
	offer: { price: { monthly: number; annually: number } },
	model: CommonModel,
	addSummedPrices: boolean
) => {
	// MONTHLY
	calc.basePrice = offer.price.monthly;
	calc.statutoryFee = 0; // not returned by api

	setAllPlusCustomerPrices(model, calc, true);

	// YEARLY
	calc.yearlyBasePrice = offer.price.annually;
	calc.yearlyStatutoryFee = 0; // not returned by api

	setAllPlusCustomerPrices(model, calc, false);

	if (addSummedPrices) {
		calc.summedBasePrice += calc.basePrice;
		calc.summedStatutoryFee = 0; // not returned by api
		calc.summedTotalPrice += calc.totalPrice;

		calc.summedYearlyBasePrice += calc.yearlyBasePrice;
		calc.summedYearlyStatutoryFee = 0; // not returned by api
		calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
	} else {
		calc.totalPriceDisplay = formatNumber.format(calc.totalPrice) + ' kr.';
		calc.yearlyPriceTotalDisplay = formatNumber.format(calc.yearlyPriceTotal) + ' kr.';
	}
};

export const mockAccident = (model: CommonModel, productType: string, addSummedPrices: boolean) => {
	warning('USING MOCK DATA!');
	let add = 2000;
	model.calculation.abCalc.packs.get(model.calculation.abCalc.excessIdDefault).forEach((calc) => {
		add += 100;
		if (calc.name === productType) {
			const offer = {
				price: { monthly: add, annually: 12 * add * 0.97 },
			};
			setAccidentPrices(calc, offer, model, addSummedPrices);
			if (!addSummedPrices) {
				updateCalcKeys(model);
			}
		}
	});
};

// handle campaigns including pluscustomer discount
export const handleIncludeDiscountPlusCustomer = (model: CommonModel) => {
	// KLK handle TIA discount
	if (model.campaign.valid && model.campaign.includeDiscountPlusCustomer) {
		model.campaign.discount = model.campaign.originalDiscount + (model.calculation.discount - 1);
		model.campaign.discountDisplay = formatNumber.format(100 * model.campaign.discount) + ' %';
	}
};

// called when user add/removes products in basket
export const basketChangeDiscountOnCalculation = (
	uiComp: CalculatorUiComponent,
	rebate: { discount: number; discountDisplay: string }
) => {
	const selectedPackage = uiComp.model.choosePackage.selectedPackage;

	uiComp.model.calculation.discount = rebate.discount;
	uiComp.model.calculation.discountDisplay = rebate.discountDisplay;

	// KLK should the be in the setAllPrices?
	handleIncludeDiscountPlusCustomer(uiComp.model);
	const special = handleSpecialCarDiscounts(uiComp.model, uiComp.cms);
	selectedPackage.specialDiscount = special.specialDiscount;

	// monthly
	setAllPlusCustomerPrices(uiComp.model, selectedPackage, true);
	// yearly
	setAllPlusCustomerPrices(uiComp.model, selectedPackage, false);
	setHighLights(uiComp.model);
};

export const getMonthlyTotalPrice = (monthly: boolean, uiComp, productNames: string[] = []): string => {
	let totalPrice = 0;
	productNames.forEach((productName) => {
		const calculator = store.getters.getCalculator(productName);
		if (calculator?.model?.readyForBasket) {
			if (monthly) {
				totalPrice += calculator.model.choosePackage.selectedPackage.totalPrice;
			} else {
				totalPrice += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
			}
		}
	});

	if (totalPrice) {
		uiComp.hasPrices = true;
		uiComp.$emit('basketPrice', totalPrice);
		return formatNumber.format(totalPrice) + ' kr.' + (monthly ? '/md' : '/år');
	}
	uiComp.$emit('basketPrice', 0);
	uiComp.hasPrices = false;
	return 'Ingen produkter beregnet endnu';
};

export const checkForValidAddress = async (uiComp: CalculatorUiComponent): Promise<boolean> => {
	store.state.showSpinner = true;
	const packageId = uiComp.model.calculation.abCalc.packageIds[0];
	const excessId = uiComp.model.calculation.abCalc.excessIdDefault;
	const ok = await uiComp.calculator.onePackageCalcEpi(true, excessId, packageId);
	store.state.showSpinner = false;
	return Promise.resolve(ok);
};
