import localforage from 'localforage';
import { formatNumber } from './ValidationService';
import { cloneDeep, merge } from 'lodash';
import { getCookieAsJson, setCookie } from './cookieService';
import { CommonModel, PackageCalculation, PkPrice } from '../types/CommonModel';
import store from '@/store/store';
import {
	getCalculatorNameUi,
	PlusCustomerType,
	PlusCustomerArray,
	stripProductIndex,
	getShortProductName,
	uniqueStringArray,
} from './ProductService';
import { CALCULATOR_UPDATE_COMMON_MODEL, CalculatorCommonModel } from '@/store/modules/calculatorContext';
import { capitalizeFirstLetter } from './UiStepService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { exception, information } from '@/appinsights/logging/ApplicationInsightLog';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';

export const upSaleCampaignName = 'upsale';
export const almbrandOnlineCampaignName = 'almbrandonline';

export interface UpSaleCampaignData {
	calculatorCommonModel: CalculatorCommonModel;
	upSaleProducts: UpSaleProduct[];
	hideUpsalePk3: boolean;
}
export interface UpSaleProduct {
	productNameDisplay: string;
	productNameDisplayMobile: string;
	productName: string;
	validUpSaleProduct: boolean;
	basePrice: number;
	yearlyBasePrice: number;
	pk0: PkPrice;
	pk1: PkPrice;
	pk2: PkPrice;
	pk3: PkPrice;
	currentLevel: PlusCustomerType;
	originalLevel: PlusCustomerType;
	monthly: boolean;
	packageName: string;
	upSaleCampaignDiscount: number;
}

interface PkPricesDisplay {
	pk0?: PkPrice;
	pk1?: PkPrice;
	pk2?: PkPrice;
	pk3?: PkPrice;
}
export const handleUpSaleCampaign = (isBasket: boolean, upsaleShowProductsAfterPK3: boolean) => {
	try {
		const basket = store.getters.getSelectableGroup('basket');
		let model: CommonModel;
		//monthly = model.choosePackage.monthYear === 'M';
		// always use yearly prices
		const monthly = false;
		let upSaleProduct: UpSaleProduct;
		let upSaleProducts: UpSaleProduct[] = [];
		let pack: PackageCalculation;
		let pkPrices: PkPricesDisplay;
		const useCommonModel = store.getters.getCalculatorCommonModel?.contact_information?.phone;
		let currentModel = useCommonModel ? store.getters.getCalculatorCommonModel : undefined;

		let highestPK3Level = PlusCustomerType.PK0;

		const noPrice = {
			price: 0,
			display: '',
		};

		if (store.state.upSaleCampaignData) {
			upSaleProducts = store.state.upSaleCampaignData.upSaleProducts;
		}

		basket.forEach((productName) => {
			const calc = store.getters.getCalculator(productName);

			const trimmedName = stripProductIndex(productName).trim().toLocaleLowerCase();
			const productNameDisplay = getCalculatorNameUi(productName);

			if (isProductUpSaleReady(calc, isBasket)) {
				model = calc.model;
				pack = model.choosePackage.selectedPackage;
				pkPrices = getMonthlyOrYearlyPkValues(monthly, pack);
				upSaleProduct = <UpSaleProduct>{
					productNameDisplay,
					productNameDisplayMobile: getShortProductName(productName),
					productName: trimmedName,
					validUpSaleProduct: isValidUpSaleProduct(calc, trimmedName),
					basePrice: pack.basePrice,
					yearlyBasePrice: pack.yearlyBasePrice,
					pk0: pkPrices.pk0,
					pk1: pkPrices.pk1,
					pk2: pkPrices.pk2,
					pk3: pkPrices.pk3,
					currentLevel: pack.currentPlusCostumerLevel,
					originalLevel: pack.currentPlusCostumerLevel,
					monthly,
					packageName: pack.name,
					upSaleCampaignDiscount: model.campaign.discount,
				};
				highestPK3Level = upSaleProduct.currentLevel;
			} else {
				//set not calculated product on customerProducts, since it's part of the pluscustomer calculation
				upSaleProduct = <UpSaleProduct>{
					productNameDisplay,
					productNameDisplayMobile: getShortProductName(productName),
					productName: trimmedName,
					validUpSaleProduct: isValidUpSaleProduct(calc, trimmedName),
					basePrice: 0,
					yearlyBasePrice: 0,
					pk0: noPrice,
					pk1: noPrice,
					pk2: noPrice,
					pk3: noPrice,
					currentLevel: PlusCustomerType.PK0,
					originalLevel: PlusCustomerType.PK0,
					monthly,
					packageName: '',
					upSaleCampaignDiscount: 0,
				};
			}
			upSaleProducts = handleReentrantSameProduct(trimmedName, upSaleProducts);

			upSaleProducts.push(upSaleProduct);
			if (!useCommonModel) {
				currentModel = model;
			}
		});

		if (upSaleProducts.length > 0) {
			const model_ = {
				personInfo: currentModel.personInfo,
				additionalInfo: currentModel.additionalInfo,
				contact_information: currentModel.contact_information,
			};
			const upSaleCampaignData: UpSaleCampaignData = {
				calculatorCommonModel: model_ as any,
				upSaleProducts,
				hideUpsalePk3: false,
			};
			store.state.upSaleCampaignData = upSaleCampaignData;

			if (!upsaleShowProductsAfterPK3 && highestPK3Level.valueOf() === PlusCustomerType.PK3.valueOf()) {
				store.state.upSaleCampaignData.hideUpsalePk3 = true;
				return;
			}
		}
	} catch (error) {
		exception(error);
	}
};

const handleReentrantSameProduct = (trimmedName: string, upSaleProducts: UpSaleProduct[]) => {
	// if user adds insurance again
	const foundProduct = upSaleProducts.find((prod) => prod.productName === trimmedName);
	if (foundProduct) {
		// remove this previous bought product, since user buys it again(?) - except car and accident
		if (
			InsurancesProductEnum.BIL_FORSIKRING !== trimmedName &&
			InsurancesProductEnum.ULYKKES_FORSIKRING !== trimmedName
		) {
			upSaleProducts = upSaleProducts.filter((prod) => prod.productName !== trimmedName);
		}
	}
	return upSaleProducts;
};

export const updateUpSaleCampaign = (
	newPlusCustomerLevel: PlusCustomerType,
	upSaleCampaignData: UpSaleCampaignData
) => {
	const upSaleCampaignDataUpdated = cloneDeep(upSaleCampaignData);
	const model: CalculatorCommonModel = upSaleCampaignDataUpdated.calculatorCommonModel;
	const upSaleProducts = upSaleCampaignDataUpdated.upSaleProducts;
	upSaleProducts.forEach((product) => {
		if (!model.personInfo.existingAlmBrandProducts.includes(product.productName)) {
			model.personInfo.existingAlmBrandProducts.push(capitalizeFirstLetter(product.productName));
		}
		product.currentLevel = newPlusCustomerLevel;
	});

	model.personInfo.existingAlmBrandProducts = uniqueStringArray(model.personInfo.existingAlmBrandProducts);
	model.personInfo.almbrandCustomer = 'ja';
	const commonModal = model as any;
	commonModal.additionalInfo.existInsurance = undefined;
	commonModal.additionalInfo.existingInsurance = undefined;
	commonModal.additionalInfo.existingInsuranceId = undefined;
	commonModal.additionalInfo.existFormerInsurance = undefined;
	commonModal.additionalInfo.formerInsurance = undefined;
	commonModal.additionalInfo.formerInsuranceId = undefined;

	commonModal.contact_information.accept = false;
	model.showExistingAlmBrandProducts = false;

	// personal information
	setCookie(
		upSaleCampaignName,
		JSON.stringify({ calculatorCommonModel: upSaleCampaignDataUpdated.calculatorCommonModel }),
		store.state.upSaleCampaignCookieTtl
	);
	// productInformation
	localforage.setItem(
		upSaleCampaignName,
		JSON.stringify({ upSaleProducts: upSaleCampaignDataUpdated.upSaleProducts })
	);

	// fallback App
	sessionStorage.setItem(upSaleCampaignName, JSON.stringify(upSaleCampaignDataUpdated));
};

const isValidUpSaleProduct = (product, name: string): boolean => {
	return product?.cms?.campaignProductsUpSale?.includes(name);
};

const isProductUpSaleReady = (product, basket: boolean): boolean => {
	if (basket) {
		return product?.model?.readyForBasket;
	}
	return product?.model?.choosePackage?.selectedPackage;
};

const getMonthlyOrYearlyPkValues = (monthly: boolean, pack?: PackageCalculation): PkPricesDisplay => {
	const pkPrices: PkPricesDisplay = <PkPricesDisplay>{};
	PlusCustomerArray.forEach((pkName) => {
		pkPrices[pkName] = pack?.pkPrices[monthly ? 'monthly' : 'yearly'][pkName];
	});
	return pkPrices;
};

export const handleCampaigns = async (comp) => {
	if (comp.model.campaign.ID && comp.model.campaign.valid) {
		// campaign already loaded (basket or localStorage)
		return;
	}
	const campaignID = comp.$route.query.partner || comp.$route.query.campaign || comp.$route.query.utm_campaign;

	if (!campaignID) {
		const hasStoredCampaign = await loadCampaignFromStorage(comp);
		if (hasStoredCampaign) {
			return; // campaign loaded from storage
		}
	}

	comp.model.campaign.ID = campaignID?.toLocaleLowerCase();
	comp.model.campaign.valid = false;

	handleDiba(comp);

	if (comp.model.campaign.ID) {
		if (comp.cms.validCampaigns.includes(comp.model.campaign.ID)) {
			comp.model.campaign.valid = true;
		}
	}

	await tryLoadUpSaleCampaign(comp);

	// standard almbrand online campaign - fallback
	if (!comp.model.campaign.valid && comp.cms.validCampaigns.includes(almbrandOnlineCampaignName)) {
		comp.model.campaign.valid = true;
		comp.model.campaign.ID = almbrandOnlineCampaignName;
	}

	loadCampaign(comp);
};

const loadCampaignFromStorage = async (comp: CalculatorUiComponent): Promise<boolean> => {
	try {
		if (!comp.cms.useLocalStorage) {
			// don't load from localStorage
			return;
		}
		const campaign: any = await localforage.getItem('campaign' + comp.model.productName);

		if (!campaign) {
			return false;
		}
		if (new Date().getTime() < campaign.expires.getTime()) {
			comp.model.campaign = campaign.campaign;
			return true;
		} else {
			// remove from storage
			localforage.removeItem('campaign' + comp.model.productName);
		}
		return false;
	} catch (error) {
		exception(error);
		return false;
	}
};

export const tryLoadUpSaleCampaign = async (comp: CalculatorUiComponent): Promise<boolean> => {
	const calculatorCommonModel =
		store.state.upSaleCampaignData?.calculatorCommonModel ||
		(getCookieAsJson(upSaleCampaignName) as UpSaleCampaignData)?.calculatorCommonModel ||
		getSessionStorageAsJson(upSaleCampaignName)?.calculatorCommonModel;

	if (!calculatorCommonModel) {
		// cleanUp
		sessionStorage.removeItem(upSaleCampaignName);
		localforage.removeItem(upSaleCampaignName);
		return false;
	}
	let upSaleCampaignData: UpSaleCampaignData;

	const upSaleProducts = JSON.parse(await localforage.getItem(upSaleCampaignName));
	if (upSaleProducts) {
		upSaleCampaignData = { calculatorCommonModel, ...upSaleProducts };
	} else {
		// App fallback
		upSaleCampaignData = getSessionStorageAsJson(upSaleCampaignName);
	}

	if (upSaleCampaignData) {
		const hasUpsaleCampaign = comp.cms.validCampaigns.includes(upSaleCampaignName);
		if (hasUpsaleCampaign) {
			comp.model.campaign.ID = upSaleCampaignName;
			comp.model.campaign.valid = true;

			// setting products already ordered
			store.state.upSaleCampaignData = cloneDeep(upSaleCampaignData);

			// copy upsale info to model
			merge(comp.model, cloneDeep(store.state.upSaleCampaignData.calculatorCommonModel));
			// force copy model to commonModel
			store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, { fromCommonModel: false, forceCopy: true });
			return true;
		}
	}
	return false;
};

const getSessionStorageAsJson = (itemName: string) => {
	const sessionObj = sessionStorage.getItem(itemName);
	try {
		const parsed = JSON.parse(sessionObj);
		return parsed;
	} catch (error) {
		// too bad
		information(error);
	}
};

const handleDiba = (comp: CalculatorUiComponent) => {
	if (comp.model.campaign.ID && comp.model.campaign.ID === 'diba') {
		if (comp.cms.validCampaigns.includes(comp.model.campaign.ID)) {
			comp.model.campaign.uniqueKey =
				(comp.$route.query.partnerGuid as string) || (comp.$route.query.partnerguid as string);
			if (comp.model.campaign.uniqueKey) {
				comp.model.campaign.valid = true;
			}
		}
	}
};

const loadCampaign = (comp: CalculatorUiComponent) => {
	if (comp.model.campaign.valid) {
		const campaign = comp.cms.campaigns.get(comp.model.campaign.ID);
		comp.model.campaign.productStepDiscount = campaign.productStepDiscount;
		comp.model.campaign.discount = campaign.discount;
		comp.model.campaign.originalDiscount = campaign.discount;
		comp.model.campaign.discountDisplay = formatNumber.format(100 * campaign.discount) + ' %';
		comp.model.campaign.tiaDiscount = campaign.tiaDiscount;
		comp.model.campaign.description = campaign.description;
		comp.model.campaign.groupId = campaign.groupId;
		comp.model.campaign.includeDiscountPlusCustomer = campaign.includeDiscountPlusCustomer;
		comp.model.campaign.splash = campaign.splash;
		comp.model.campaign.splashIcons = campaign.splashIcons;
		if (comp.cms.useLocalStorage) {
			// save to localStorage
			const expires = new Date();
			expires.setTime(new Date().getTime() + comp.cms.campaignStoreValidMillis);
			// cloneDeep, since localForage cannot persist vue proxy
			localforage.setItem(
				'campaign' + comp.model.productName,
				cloneDeep({
					campaign: comp.model.campaign,
					expires,
				})
			);
		}
	}
};
